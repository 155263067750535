import React, { useState, useContext } from 'react'
import { navigate } from 'gatsby'
import { toast } from 'react-toastify'
import SyncLoader from 'react-spinners/SyncLoader'

import './index.scss'

import { Context } from '../Context'
import Layout from '../components/Layout'
import Button from '../components/Button'
import PUSH from '../dao/push'
import { postLogin } from '../services/api/login'

const IndexPage = () => {
  const { isAuth, activateAuth } = useContext(Context)

  if (isAuth) {
    navigate('/dashboard/')

    return (
      <Layout withoutHeader className='page-login'>
        Cargando...
      </Layout>
    )
  }

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(false)

  function resetPassword () {
    if (email.length < 5) {
      toast.error('El email es obligatorio para resetear la contraseña.')
      return
    }

    PUSH('PUT', 'users', { email }, 'action=request_password').then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      } else {
        toast.success(res.body.message)
      }
    })
  }

  function submitLogin (e, email, password, token2fa) {
    e.preventDefault()

    if (
      email.trim().length === 0 ||
      password.trim().length === 0 ||
      token2fa.trim().length === 0
    ) {
      toast.error('Todos los campos son obligatorios.')
      return
    }

    const body = {
      email: email,
      password: password,
      token2fa: token2fa
    }

    setLoading(true)
    postLogin(body)
      .then(async res => {
        if (res.status === 200) {
          activateAuth(res.data.token)
        }
      })
      .catch(err => {
        if (err.response) { toast.error(err.response.data.message) }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Layout bgSuffix='1' withoutHeader className='page-login'>
      <form
        className='login-form'
        onSubmit={e => submitLogin(e, email, password, token)}
      >
        <div className='app-name' />

        <input
          type='email'
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder='Usuario'
          className='textfield'
          autoComplete='off'
        />

        <input
          type='password'
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeholder='Contraseña'
          className='textfield'
          autoComplete='off'
        />

        <input
          type='text'
          value={token}
          onChange={e => setToken(e.target.value)}
          placeholder='Token'
          className='textfield'
          autoComplete='off'
        />

        <p className='reset-password' onClick={resetPassword}>
          Si has olvidado tu contraseña haz click aquí.
        </p>

        <div className='text-center'>
          <Button className='btn' type='submit'>
            Entrar
          </Button>
          <div style={{ margin: '30px auto 0' }}>
            <SyncLoader color='#fff' loading={loading} />
          </div>
        </div>
      </form>
    </Layout>
  )
}

export default IndexPage
